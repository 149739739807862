<template>
  <plc-page :slide="false" :pic-url="banner">
    <div class="fixed-width page-content page-partner">
      <div class="submenu-wrapper">
        <div class="item item-1 active">
          <div class="submenu-name">合作伙伴</div>
          <img class="submenu-arrow" src="../assets/images/arrow-down-white.png">
        </div>
      </div>
      <div class="title">感恩伙伴，合作共赢。</div>
      <div class="parter-wrapper">
        <div v-for="item in partner" :key="item.name" class="partner-item">
          <img class="partner-pic" :src="item.picUrl">
          <div class="partner-name">{{item.name}}</div>
        </div>
      </div>
    </div>
  </plc-page>
</template>

<script type="text/ecmascript-6">
  import PlcPage from '../components/page/Index'
  import { CASES } from '../util/constants'

  export default {
    name: 'Case',
    components: {
      PlcPage
    },
    data () {
      return {
        banner: require('../assets/images/banner/banner-partner.jpg'),
        partner: CASES
      }
    },
    methods: {}
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .page-partner
    .parter-wrapper
      display flex
      flex-wrap wrap
      align-items center
      justify-content space-between
      .partner-item
        width 251px
        box-sizing border-box
        margin-top 35px
        .partner-pic
          width 251px
          height 140px
        .partner-name
          display flex
          align-items center
          justify-content center
          padding 16px 0
          color #1e1e1e
          font-weight 600
    .title
      width 100%
      text-align center
      color #1e1e1e
      margin-bottom 25px
      font-size 22px
      font-weight 500
</style>
